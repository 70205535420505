/* line 30, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
.c-listing-item--gallery .listing-item__feat--inline {
	font-size: 1.5em;
}

@media only screen and (max-width: 47.999em) {
	/* line 30, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat--inline {
		font-size: 1.05em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 30, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat--inline {
		font-size: 1.2495em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 30, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat--inline {
		font-size: 1.5em;
	}
}

/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
.c-listing-item--gallery .listing-item__feat__description {
	font-size: 0.5em;
	color: rgb(var(--color-global-helper));
}

@media only screen and (max-width: 47.999em) {
	/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__description {
		font-size: 0.5em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__description {
		font-size: 0.5em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__description {
		font-size: 0.5em;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__description {
		font-size: 0.7em;
	}
}

@media only screen and (max-width: 47.999em) and (max-width: 47.999em) {
	/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__description {
		font-size: 0.7em;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 48em) and (max-width: 79.999em) {
	/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__description {
		font-size: 0.7em;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 80em) {
	/* line 34, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__description {
		font-size: 0.7em;
	}
}

/* line 42, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
.c-listing-item--gallery .listing-item__feat__description::after {
	display: none;
}

/* line 50, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
.c-listing-item--gallery .listing-item__boxes .listing-item__feat:not(.listing-item__feat--placeholder) {
	box-sizing: border-box;
	background: rgb(var(--color-global-positive));
	margin: 0;
	width: 100%;
	border: 1px solid rgb(var(--color-global-widget2));
}

@media only screen and (min-width: 30em) and (max-width: 104.999em) {
	/* line 58, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__boxes .listing-item__feat:not(.listing-item__feat--placeholder):not(.listing-item__openhouse) {
		padding-bottom: 5px;
	}
}

@media only screen and (min-width: 105em) {
	/* line 67, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__value {
		font-size: 0.7em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 104.999em) {
	/* line 73, scss/80-themes/Saunders/60-components/listing-item-boxes.scss */
	.c-listing-item--gallery .listing-item__feat__value {
		font-size: 0.9em;
	}
}

/*# sourceMappingURL=../../../../true */